<template>
    <div class="card bs-5 mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 class="text-uppercase" v-html="$route.params.name"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.linkAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disable="{rowData}">
                <checkbox-input v-if="!rowData.loading" v-model="rowData.disable" @input="disable(rowData)" />
                <loading-animation v-else/>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Link" ref="linkAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddLink @success="formSuccess"></AddLink>
        </modal>

        <modal title="Edit Link" ref="linkEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditLink :initial-data="editingItem" @success="formSuccess"></EditLink>
        </modal>

        <delete-modal ref="linkDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Link <b v-html="deletingItem && deletingItem.link"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import AddLink from './AddLink';
import EditLink from './EditLink';
import axios from 'secure-axios';

export default {
    name       : 'Link',
    components : { AddLink, EditLink },
    data () {
        return {
            listUrl      : urls.impactStories.links.list + '?story=' + this.$route.params.id,
            deleteUrl    : urls.impactStories.links.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'link',
                    sortField  : 'link',
                    title      : 'Link',
                    titleClass : ''
                },
                {
                    name       : '__slot:disable',
                    sortField  : 'disable',
                    title      : 'Disable',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.linkAddModal.close();
            refs.linkEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.linkEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.linkDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Link..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.linkDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            item.loading = true;
            axios.form(urls.impactStories.links.addEdit, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Project..!', 'Success',
                        {
                            type : 'success'
                        });
                    item.loading = false;
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disable = !item.disable;
                    item.loading = false;
                }
            }).catch(function (err) {
                item.disable = !item.disable;
                item.loading = false;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
