<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{story: $route.params.id}">

        <div class="row col-12">
            <validated-input class="col-12" label="Link" name="Link" v-model="model.link"
                         :rules="rules.link" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" icon="fa fa-save" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'AddLink',
    data () {
        return {
            addUrl : urls.impactStories.links.addEdit,
            rules  : {
                link : {
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Impact Stories Link..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
